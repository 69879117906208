<template>
    <div>
        <h2 class="align-center heading">{{ title }}</h2>
        <v-flex class="btn-padding">
            <v-btn block :style="theme" @click="$emit('selectProduct')">
                <v-icon>mdi-plus</v-icon>{{ btnTitle }}
            </v-btn>
        </v-flex>
        <v-card flat>
            <v-list three-line class="product-card" sm12 xs12 v-if="products.length > 0">
                <template v-for="(product, index) in products">
                    <v-list-item :key="product._id">
                        <v-list-item-avatar rounded="0">
                            <!-- <v-img v-if="product?.images[0]" :src="imagePath(product?.images[0].path)"
                            height="64px"></v-img> -->
                            <v-img :src="defaultProductImage" height="64px" width="100%"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ product.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ product.code }}</v-list-item-subtitle>
                            <v-list-item-subtitle><label class="card-subtitle-font">Rs. </label>
                                <label class="card-title-font">{{ product.price || product.sellingPrice
                                    }}</label></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn fab class="ma-1" :style="theme" x-small @click="$emit('delete', product)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                </template>
            </v-list>
        </v-card>
    </div>
</template>

<script>
import ProductCard from '@/components/ProductResponsiveCard'
export default {
    components: {
        ProductCard,
    },
    props: {
        title: {
            type: String,
            default: () => ""
        },
        btnTitle: {
            type: String,
            default: () => ""
        },
        products: {
            type: Array,
            default: () => []
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-padding {
    padding: 2% 20% !important
}

.align-center {
    text-align: center
}

.heading {
    padding: 1% 0%
}

.product-card {
    padding: 0% 25% !important;
}
</style>
