<template>
    <div class="display-block">
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
        <product-bundle-form v-if="currentStep == 1" @add="addProductBundle" :product="productBundle" />
        <v-card v-if="currentStep == 2">
            <product-selection @selectProduct="showDialog = true" @delete="removeProduct($event)" title="Select Product"
                btnTitle="Add Product" :products="productBundle.bType == 'FIXED'?productBundle.swapOptions:productBundle.swapOptions.length>0?[productBundle.swapOptions[0]]:[]"></product-selection>
            <product-selection @selectProduct="selectSwap()" @delete="removeProduct($event)"
                v-if="productBundle.bType != 'FIXED'" title="Select Swap Options" btnTitle="Add Swap Product"
                :products="productBundle.swapOptions.slice(1)"></product-selection>
        </v-card>
        <v-flex v-if="currentStep == 2" text-right class="mt-6">
            <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
            <v-btn class="mr-4" :style="theme" @click="calculatePrice(productBundle.swapOptions)">Next</v-btn>
        </v-flex>
        <product-select-pop-up productType="SIMPLE" v-if="!swapProductFlag"
            :multiSelect="productBundle.bType == 'FIXED'" :showDialog="showDialog" @close="showDialog = false"
            @save="saveProduct($event)" />
        <product-select-pop-up productType="SIMPLE" v-if="swapProductFlag" :showDialog="showDialog"
            @close="showDialog = false" @save="saveProduct($event)" />
        <v-card v-if="currentStep == 3">
            <h2 class="align-center heading">Configure Price</h2>
            <v-flex class="mx-12">
                <row-item label="Suggested Price" :value="suggestedPrice" />
                <row-item label="Configure Price" />
                <v-text-field type="number" v-model.number="productBundle.price" outlined></v-text-field>
            </v-flex>
        </v-card>
        <v-flex v-if="currentStep == 3" text-right class="mt-6">
            <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
            <v-btn class="mr-4" :style="theme" @click="goNext()">Next</v-btn>
        </v-flex>
        <v-card v-if="currentStep == 4">
            <h2 class="align-center heading">Review Your Product Bundle</h2>
            <v-flex class="mx-12">
                <row-item label="Name" :value="productBundle.title"></row-item>
                <row-item label="Type" :value="productBundle.type == 'SIMPLE' ? 'Simple' : 'Bundle'"></row-item>
                <row-item label="Buy Type"
                    :value="productBundle.bType == 'FIXED' ? 'Fixed' : 'Customizable'"></row-item>
                <row-item label="Required Items"></row-item>
                <v-layout v-if="productBundle.swapOptions?.length > 0">
                    <v-flex sm2 v-for="product in (productBundle.bType=='FIXED'?productBundle.swapOptions:[productBundle.swapOptions[0]])" :key="product._id">
                        <v-chip>{{ product.title }}</v-chip>
                    </v-flex>
                </v-layout>
                <row-item v-if="productBundle.swapOptions.length > 1 && productBundle.bType=='CUSTOMIZABLE'" label="Swap Items"></row-item>
                <v-layout v-if="productBundle.swapOptions.length > 1 && productBundle.bType=='CUSTOMIZABLE'">
                    <v-flex sm2 v-for="product in productBundle.swapOptions.slice(1)" :key="product._id">
                        <v-chip>{{ product.title }}</v-chip>
                    </v-flex>
                </v-layout>
                <row-item label="Price" :value="productBundle.price"></row-item>
                <v-layout wrap>
                    <v-flex xs12 sm4 md3 class="pa-1" v-for="image in productBundle.images" :key="image.filename" width="200">
                        <video-card :src="image.path" v-if="image.mimetype.startsWith('video/')"></video-card>
                        <image-card :src="image.path" v-else></image-card>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-card>
        <v-flex v-if="currentStep == 4" text-right class="mt-6">
            <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
            <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
        </v-flex>
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
    </div>
</template>

<script>
import ProductBundleForm from '@/components/ProductBundleForm.vue'
import ProductSelection from '@/components/ProductSelection.vue'
import ProductSelectPopUp from '@/components/ProductSelectPopUp.vue'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        ProductBundleForm,
        ProductSelection,
        ProductSelectPopUp
    },
    data() {
        return {
            currentStep: 1,
            length: 4,
            productBundle: {
                title: '',
                desc: '',
                type: 'BUNDLE',
                bType: 'FIXED',
                products: [],
                swapOptions: [],
                code: '',
                price: 0,
                images:[]
            },
            showDialog: false,
            swapProductFlag: false,
            oldbType: '',
            suggestedPrice: 0,
            images:[]
        }
    },
    computed: {
        progress() {
            return this.currentStep / this.length * 100
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.productBundle['vendorId'] = this.getUserProfile().vendorId
            if (this.id != 0) {
                this.productBundle = await this.getItem(appConstants.PRODUCTS_API + "/" + this.id)
                this.oldbType = this.productBundle.bType
            }
        },
        goBack() {
            this.currentStep--
        },
        goNext() {
            this.currentStep++
        },
        async addProductBundle(value) {
            this.productBundle = value
            if (this.oldbType != this.productBundle.bType)
                this.productBundle.swapOptions = []
            this.images = this.productBundle.uploadImages
            this.goNext()
        },
        selectSwap() {
            this.showDialog = true
            this.swapProductFlag = true
        },
        saveProduct(products) {
            if(this.productBundle.swapOptions.length==0&&this.swapProductFlag){
                alert('Please Select one required item')
                this.swapProductFlag=false
            }
            else{
                if(this.productBundle.bType=='CUSTOMIZABLE' && this.productBundle.swapOptions.length>1 && !this.swapProductFlag)
                    this.productBundle.swapOptions[0]=products[0]
                else{
                    products.forEach(rec => {
                        if (!this.productBundle.swapOptions.includes(rec))
                            this.productBundle.swapOptions.push(rec)
                    })
                    if(this.swapProductFlag)
                        this.swapProductFlag=false
                }
            }
        },
        submit() {
            this.productBundle.code = this.productBundle.code||'P-' + btoa(Math.random()).substr(5, 5).toUpperCase()
            if (this.productBundle.swapOptions.length > 0)
                this.productBundle.swapOptions = this.convertToProductRefModel(this.productBundle.swapOptions, this.productBundle.type, this.productBundle.bType)
            this.productBundle.price = this.productBundle.price || this.suggestedPrice
            let formData = this.convertToFormData(this.productBundle, this.images, 'productImages')
            if (this.id == 0)
                this.saveItem(formData)
            else
                this.updateItem(formData)
            this.$router.push("/app/products")
        },
        async saveItem(formData) {
            try {
                await this.postItem(appConstants.PRODUCTS_API, formData)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
                this.loading = false
            }
        },
        async updateItem(formData) {
            try {
                await this.putItem(appConstants.PRODUCTS_API + "/" + this.productBundle._id, formData)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
                this.loading = false
            }
        },
        calculatePrice(products) {
            this.suggestedPrice = 0
            if(this.productBundle.bType=='FIXED'){
                products.forEach(rec => {
                    this.suggestedPrice += (rec.price || rec.sellingPrice)
                })
            }
            else
                this.suggestedPrice=products[0].price||products[0].sellingPrice
            this.goNext()
        },
        removeProduct(product) {
            let index = this.productBundle.swapOptions.findIndex(rec => rec._id == product._id)
            if (index > -1)
                this.productBundle.swapOptions.splice(index, 1)
        },
    },
};
</script>

<style scoped>
.display-block {
    padding: 5% 15%;
}

.align-center {
    text-align: center
}
</style>
