<template>
    <div>
        <v-card class="mx-auto" flat>
            <h3 class="align-center heading">Product Bundle Details</h3>
            <v-card-text>
                <v-form ref="form" lazy-validation class="ma-6">
                    <v-flex>
                        <v-text-field label="Name" v-model="product.title" :rules="titleRules" required />
                    </v-flex>
                    <v-flex>
                        <label>Description</label>
                        <!-- <wysiwyg v-model="product.desc"></wysiwyg> -->
                        <v-textarea v-model="product.desc" rows="3"></v-textarea>
                    </v-flex>
                    <v-flex>
                        <v-radio-group label="Type" v-model="product.bType" mandatory>
                            <v-radio label="Fixed" value="FIXED"></v-radio>
                            <v-radio label="Customizable" value="CUSTOMIZABLE"></v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-layout wrap>
                        <v-flex xs12 sm4 md3 class="pa-1" v-for="(image, index) in product.images" :key="index"
                            width="200">
                            <video-card :src="image.path" @close="removeImage(index)" :showClose="true"
                                v-if="image?.mimetype.startsWith('video/')"></video-card>
                            <image-card :src="image?.path" @close="removeImage(index)" :showClose="true"
                                v-else></image-card>
                        </v-flex>
                    </v-layout>
                    <v-flex>
                        <h4>Add Product Image</h4><br>
                        <file-upload ref="fileupload" v-model="images" label="Upload Images"
                            :multiple="true"></file-upload>
                        <li v-for="(image, index) in images" :key="index">
                            {{ image.name }}
                        </li>
                    </v-flex>
                </v-form>
            </v-card-text>
        </v-card>
        <v-flex text-right class="mt-6">
            <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
            <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
        </v-flex>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            images: []
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.product.uploadImages = this.images
                this.$emit('add', this.product)
            }
        },
        removeImage(index) {
            this.product.images.splice(index, 1)
        },
    },
}
</script>

<style scoped>
.align-center {
    text-align: center
}

.heading {
    padding: 2% 0%;
}
</style>
