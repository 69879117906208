<template>
    <div>
        <app-dialog :show="show" width='30%' @close="show = !show">
            <h2 class="align-center">Select Required Items</h2>
            <v-card flat>
                <v-card-text>
                    <v-text-field class="search pa-4" @input="updateApiCall(apiCall, search)" v-model="search"
                        label="Search Product" append-icon="mdi-magnify"></v-text-field>
                    <v-row>
                        <v-col cols="6" v-for="product in products" :key="product._id">
                            <v-card class="ma-1 multi-select-card" :class="{ selected: isSelected(product) }"
                                @click="toggleSelection(product)">
                                <v-col>
                                    <v-row class="align-card-image">
                                        <v-img v-if="product?.images[0]" :src="imagePath(product?.images[0].path)"
                                            height="64px"></v-img>
                                        <v-img v-else :src="defaultProductImage" height="64px"></v-img>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row justify="center">
                                        <label wrap class="card-title-font" v-text="product?.title"></label>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <pagination ref="pagination" :api="apiCall" v-model="products" :update="search != ''"></pagination>
            <v-flex text-right class="mt-6">
                <v-btn class="mr-4" :style="themeInverted" outlined @click="show = !show">Cancel</v-btn>
                <v-btn class="mr-4" :style="theme" @click="submit()">Submit</v-btn>
            </v-flex>
        </app-dialog>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {

    props: {
        showDialog: {
            type: Boolean,
            default: () => false
        },
        multiSelect: {
            type: Boolean,
            default: () => true
        },
        productType: {
            type: String
        }
    },
    data() {
        return {
            search: '',
            products: [],
            selectedItems: [],
            apiCall: appConstants.PRODUCTS_API
        }
    },
    mounted() {
        if (this.productType == 'SIMPLE')
            this.apiCall = this.apiCall + '?conditions=type!=BUNDLE';
    },
    computed: {
        show: {
            get() {
                return this.showDialog
            },
            set(newValue) {
                this.$emit('close')
            }
        }
    },
    methods: {
        toggleSelection(product) {
            if (this.isSelected(product)) {
                const index = this.selectedItems.indexOf(product);
                if (index !== -1)
                    this.selectedItems.splice(index, 1);
            } else {
                if (!this.multiSelect) {
                    this.selectedItems = []
                    this.selectedItems.push(product)
                } else
                    this.selectedItems.push(product);
            }
        },
        isSelected(product) {
            return this.selectedItems.includes(product);
        },
        submit() {
            this.show = false
            this.$emit('save', this.selectedItems)
            this.selectedItems = []
        }
    },
}
</script>

<style lang="scss" scoped>
.multi-select-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.selected {
    background-color: lightblue;
}

.align-center {
    text-align: center
}
</style>
